import React, { FC } from 'react'
import { useBurgerStyles } from './BurgerStyles'

export const Burger: FC = () => {
  const { line } = useBurgerStyles()
  return (
    <>
      {['', '', ''].map(() => (
        <div className={line}/>
      ))}
    </>
  )
}

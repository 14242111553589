import React, { FC } from 'react'
import battue1 from '../../../assets/target/battue-1.jpg'
import battue2 from '../../../assets/target/battue-2.jpg'
import battue3 from '../../../assets/target/battue-3.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Battue: FC = () => (
  <TargetType header={i18n('TARGET.BATTUE')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="108"
        thickness="8"
        weight="80"
        countPerPack="240"
        size="46 Х 23 Х 25"
        weightBrutto="19,7"
        packs="50"
        total="12 000"
        totalWeight="1005"
      />
    </TargetInfoContainer>

    <TargetImages images={[battue1, battue2, battue3]}/>
  </TargetType>
)

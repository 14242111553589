import React, { FC } from 'react'
import { AppBar, Toolbar, useMediaQuery } from '@material-ui/core'
import { useAppbarStyles } from './AppHeaderStyles'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import { AppHeaderMenuButtons } from './AppHeaderMenuButtons'
import { AppHeaderBurgerMenu } from './AppHeaderBurgerMenu'

export const AppHeader: FC = () => {
  const { toolbar, appbar } = useAppbarStyles()
  const showBurger = useMediaQuery('(max-width:600px)')

  return (
    <AppBar position="static" className={appbar}>
      <Toolbar className={toolbar}>
        {!showBurger && <AppHeaderMenuButtons/>}
        {showBurger && <AppHeaderBurgerMenu/>}
        <LanguageSwitch/>
      </Toolbar>
    </AppBar>
  )
}

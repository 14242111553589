import React, { FC } from 'react'
import rabbitDefault1 from '../../../assets/target/rabbit-default-1.jpg'
import rabbitDefault2 from '../../../assets/target/rabbit-default-2.jpg'
import rabbitDefault3 from '../../../assets/target/rabbit-default-3.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const RabbitDefault: FC = () => (
  <TargetType header={i18n('TARGET.RABBIT.DEFAULT')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="110"
        thickness="16"
        weight="115"
        countPerPack="200"
        size="46 Х 23 Х 30"
        weightBrutto="23,5"
        packs="40"
        total="8 000"
        totalWeight="960"
      />

      <strong>{i18n('TARGET.RABBIT.DEFAULT.INFO')}</strong>
    </TargetInfoContainer>

    <TargetImages images={[rabbitDefault1, rabbitDefault2, rabbitDefault3]}/>
  </TargetType>
)

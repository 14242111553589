import React, { FC } from 'react'
import rabbitNinetySmall1 from '../../../assets/target/rabbit-988-11-1.jpg'
import rabbitNinetySmall2 from '../../../assets/target/rabbit-988-11-2.jpg'
import rabbitNinetySmall3 from '../../../assets/target/rabbit-988-11-3.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Rabbit98816: FC = () => (
  <TargetType header={i18n('TARGET.RABBIT.988-11')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="98,8"
        thickness="11"
        weight="100"
        countPerPack="184"
        size="46 Х 23 Х 25"
        weightBrutto="18,9"
        packs="50"
        total="9 200"
        totalWeight="965"
      />
    </TargetInfoContainer>

    <TargetImages images={[rabbitNinetySmall1, rabbitNinetySmall2, rabbitNinetySmall3]}/>
  </TargetType>
)

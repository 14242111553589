import { makeStyles } from '@material-ui/core/styles'

export const useAppStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 1200,
    margin: '2% auto',
    minHeight: 'calc(100vh - 125px - 40px - 48px)'
  },
  paper: {
    padding: '1%',
    marginBottom: 20
  }
})

import { makeStyles } from '@material-ui/core/styles'

export const useBurgerStyles = makeStyles({
  line: {
    width: 30,
    height: 2,
    backgroundColor: 'white',
    margin: '6px 11px'
  }
})

import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { i18n } from '../../i18n/i18n'
import { useAppbarStyles } from './AppHeaderStyles'
import { appHeaderMenuItemsList } from './appHeaderMenuItemsList'

export const AppHeaderMenuButtons: FC = () => {
  const { button } = useAppbarStyles()

  return (
    <>
      {appHeaderMenuItemsList.map(({ link, name, disabled }, index) => (
        <Button
          classes={{ root: button }}
          component={Link}
          to={link}
          {...(disabled ? { disabled } : {})}
        >
          {i18n(name)}
        </Button>
      ))}
    </>
  )
}

import React, { FC } from 'react'
import mini1 from '../../../assets/target/mini-1.jpg'
import mini2 from '../../../assets/target/mini-2.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Mini: FC = () => (
  <TargetType header={i18n('TARGET.MINI')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="60"
        height="25"
        weight="40"
        countPerPack="480"
        size="46 Х 23 Х 30"
        weightBrutto="19,7"
        packs="40"
        total="19 200"
        totalWeight="810"
      />
    </TargetInfoContainer>

    <TargetImages images={[mini1, mini2]}/>
  </TargetType>
)

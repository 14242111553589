import React, { FC } from 'react'
import rabbitNasta1 from '../../../assets/target/rabbit-nasta-1.jpg'
import rabbitNasta2 from '../../../assets/target/rabbit-nasta-2.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const RabbitNasta: FC = () => (
  <TargetType header={i18n('TARGET.RABBIT.NASTA')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="110"
        thickness="16"
        weight="108"
        countPerPack="184"
        size="46 Х 23 Х 25"
        weightBrutto="20,4"
        packs="50"
        total="8 000"
        totalWeight="1040"
      />

      <strong>{i18n('TARGET.RABBIT.NASTA.INFO')}</strong>
    </TargetInfoContainer>

    <TargetImages images={[rabbitNasta1, rabbitNasta2]}/>
  </TargetType>
)

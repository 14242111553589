import React, { FC } from 'react'
import facility1 from '../../assets/facility-1.jpg'
import facility4 from '../../assets/facility-4.jpg'
import facility2 from '../../assets/facility-2.jpg'
import { i18n } from '../../i18n/i18n'
import { PageContentWrapper } from '../PageContentWrapper/PageContentWrapper'

export const About: FC = () => (
  <>
    <h1>{i18n('HEADER.ABOUT')}</h1>
    <PageContentWrapper>
      <p>{i18n('ABOUT.TEXT', 0)}</p>
      <p>
        {i18n('ABOUT.TEXT', 1)}
      </p>
      <p>
        {i18n('ABOUT.TEXT', 2)}
      </p>
      <p>
        {i18n('ABOUT.TEXT', 3)}
      </p>
      <div>
        <img src={facility1} width={300} style={{ margin: '10px' }} alt=""/>

        <img src={facility4} width={300} style={{ margin: '10px' }} alt=""/>

        <img src={facility2} width={300} style={{ margin: '10px' }} alt=""/>
      </div>
    </PageContentWrapper>
  </>
)

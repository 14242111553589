import 'leaflet/dist/leaflet.css'
import React, { FC } from 'react'
import { i18n } from '../../i18n/i18n'
import { PageContentWrapper } from '../PageContentWrapper/PageContentWrapper'
import { Shipments } from './Shipments'

export const Partners: FC = () => (
  <>
    <h1>{i18n('HEADER.PARTNERS')}</h1>
    <PageContentWrapper>
      <h2>{i18n('PARTNERS.NASTA', 0)}</h2>
      <p>{i18n('PARTNERS.NASTA', 1)}</p>
      <p>{i18n('PARTNERS.NASTA', 2)}</p>
      <p>{i18n('PARTNERS.NASTA', 3)}</p>

      <a href="../../assets/nastaInfo.doc" download>{i18n('PARTNERS.NASTA.DOWNLOAD')}</a>
    </PageContentWrapper>
    <PageContentWrapper>
      <h2>
        {i18n('PARTNERS.BOWMANTRAP', 0)}
        <a href="http://www.bowmantraps.co.uk" target="_blank" rel="noopener noreferrer">bowmantraps.co.uk</a>
      </h2>
      <p>{i18n('PARTNERS.BOWMANTRAP', 1)}</p>
    </PageContentWrapper>
    <PageContentWrapper>
      <h2>
        {i18n('PARTNERS.RANGEMASTER', 0)}
        <a href="https://www.rangemaster.at/static/ru_index.htm" target="_blank" rel="noopener noreferrer">rangemaster.at</a>
      </h2>
      <p>{i18n('PARTNERS.RANGEMASTER', 1)}</p>
      <p>{i18n('PARTNERS.RANGEMASTER', 2)}</p>
    </PageContentWrapper>

    <h1>{i18n('HEADER.SHIPMENTS')}</h1>
    <Shipments/>
  </>
)

import React, { FC, useCallback, useState } from 'react'
import { Divider, Drawer, IconButton, List, ListItem, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { i18n } from '../../i18n/i18n'
import { appHeaderMenuItemsList } from './appHeaderMenuItemsList'
import { makeStyles } from '@material-ui/core/styles'
import { Burger } from '../Burger/Burger'

const style = makeStyles({
  label: {
    display: 'block'
  }
})

export const AppHeaderBurgerMenu: FC = () => {
  const { label } = style()
  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => setOpen(!open), [open])

  return (
    <>
      <IconButton classes={{ label }} onClick={toggleOpen} edge="start" color="inherit" aria-label="menu">
        <Burger/>
      </IconButton>
      <Drawer
        open={open}
        onClose={toggleOpen}
      >
        <List>
          {appHeaderMenuItemsList.map(({ link, name, disabled }, index) => (
            <>
              <ListItem onClick={toggleOpen} button component={Link} to={link} {...(disabled ? { disabled } : {})}>
                <ListItemText primary={i18n(name)}/>
              </ListItem>
              <Divider/>
            </>
          ))}
        </List>
      </Drawer>
    </>
  )
}

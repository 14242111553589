export const currentLang = localStorage.getItem('lang') || navigator.language.split('-')[0] || 'ru'
const translations: { [name: string]: string } = require(`./langs/${currentLang}.json`)

export const i18n = (key: string, index: number = 0, ...replacements: string[]): string => {
  let val = translations[key]
  val = Array.isArray(val) ? val[index] : val

  console.assert(val !== undefined, key)

  if (replacements.length) {
    let i = 0
    const replaceFn = () => replacements[i++]
    val = val.replace(/{(.*?)}/gim, replaceFn)
  }
  return val
}

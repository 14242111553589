import React, { FC } from 'react'
import { i18n } from '../../../../i18n/i18n'

interface ClaysInfoProps {
  diameter: string;
  height?: string;
  weight: string;
  countPerPack: string;
  size: string;
  weightBrutto: string;
  packs: string;
  total: string;
  totalWeight: string;
  thickness?: string;
}

export const TargetInfo: FC<ClaysInfoProps> = (
  {
    diameter,
    height,
    weight,
    countPerPack,
    size,
    weightBrutto,
    packs,
    total,
    totalWeight,
    thickness,
  }) => (
  <ul>
    <li>{i18n('TARGET.INFO', 0, diameter)}</li>
    {height && <li>{i18n('TARGET.INFO', 1, height)}</li>}
    {thickness && <li>{i18n('TARGET.INFO', 2, thickness)}</li>}
    <li>{i18n('TARGET.INFO', 3, weight)}</li>
    <li>{i18n('TARGET.INFO', 4)}</li>
    <li>{i18n('TARGET.INFO', 5, countPerPack)}</li>
    <li>{i18n('TARGET.INFO', 6, size)}</li>
    <li>{i18n('TARGET.INFO', 7, weightBrutto)}</li>
    <li>{i18n('TARGET.INFO', 8, packs, total)}</li>
    <li>{i18n('TARGET.INFO', 9, totalWeight)}</li>
    <li>{i18n('TARGET.INFO', 10)}</li>
  </ul>
)

import React, { FC } from 'react'
import DocumentMeta, { DocumentMetaProps } from 'react-document-meta'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { About } from '../About/About'
import { AppFooter } from '../AppFooter/AppFooter'
import { AppHeader } from '../AppHeader/AppHeader'
import { News } from '../News/News'
import { Partners } from '../Partners/Partners'
import { Targets } from '../Targets/Targets'
import { useAppStyles } from './AppStyles'

export const App: FC = () => {
  const { root } = useAppStyles()
  const meta: DocumentMetaProps & { meta: Record<string, string | Record<string, string>> } = {
    title: 'ZACHET LTD',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  }

  return (
    <HashRouter>
      <DocumentMeta {...meta}>
        <AppHeader/>
        <Switch>
          <div className={root}>
            <Route exact path="/" component={About}/>
            <Route exact path="/targets" component={Targets}/>
            <Route exact path="/partners" component={Partners}/>
            <Route exact path="/news" component={News}/>
          </div>
        </Switch>
      </DocumentMeta>
      <AppFooter/>
    </HashRouter>
  )
}

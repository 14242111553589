import { Paper } from '@material-ui/core'
import React, { FC } from 'react'
import { useAppStyles } from '../App/AppStyles'

export const PageContentWrapper: FC = ({ children }) => {
  const { paper } = useAppStyles()

  return (
    <Paper elevation={3} className={paper}>
      {children}
    </Paper>
  )
}

import React, { FC } from 'react'
import { i18n } from '../../i18n/i18n'
import { PageContentWrapper } from '../PageContentWrapper/PageContentWrapper'

export const News: FC = () => (
  <>
    <h1>{i18n('HEADER.NEWS')}</h1>
    <PageContentWrapper>

    </PageContentWrapper>
  </>
)

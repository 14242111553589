import React, { FC } from 'react'
import midi1 from '../../../assets/target/midi-1.jpg'
import midi2 from '../../../assets/target/midi-2.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Midi: FC = () => (
  <TargetType header={i18n('TARGET.MIDI')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="90"
        height="25"
        weight="80"
        countPerPack="220"
        size="46 Х 23 Х 30"
        weightBrutto="18,1"
        packs="50"
        total="11 000"
        totalWeight="925"
      />
    </TargetInfoContainer>

    <TargetImages images={[midi1, midi2]}/>
  </TargetType>
)

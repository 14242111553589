import { makeStyles } from '@material-ui/core/styles'

export const useLanguageSwitchStyles = makeStyles({
  root: {
    marginLeft: 'auto'
  },
  select: {
    color: '#fff',
    '&:before': {
      borderColor: '#fff',
    },
    '&:after': {
      borderColor: '#fff',
    }
  },
  selectMenu: {
    fill: '#fff',
  }

})

import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

export const useClaysImagesStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5% -5px -5px',

    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column wrap'
    },

    '& img': {
      maxWidth: '300px',
      padding: '5px',
      borderRadius: '10px'
    }
  }
}))

export const TargetImages: FC<{ images: string[] }> = ({ images }) => {
  const { root } = useClaysImagesStyles()

  return (
    <div className={root}>
      {images.map((img, index) =>
        <img src={img} alt="" key={index}/>
      )}
    </div>
  )
}

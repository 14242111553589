import { makeStyles } from '@material-ui/core/styles'

export const useAppFooterStyles = makeStyles(theme => ({
  container: {
    padding: '10px 0 10px 0'
  },
  heading: {
    margin: 0
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column wrap'
    }
  }
}))

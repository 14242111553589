import React, { FC } from 'react'
import { i18n } from '../../i18n/i18n'
import { Battue } from './Types/Battue'
import { Default } from './Types/Default'
import { Midi } from './Types/Midi'
import { Mini } from './Types/Mini'
import { Rabbit98811 } from './Types/Rabbit98811'
import { Rabbit98816 } from './Types/Rabbit98816'
import { RabbitDefault } from './Types/RabbitDefault'
import { RabbitNasta } from './Types/RabbitNasta'

export const Targets: FC = () => (
  <>
    <h1>{i18n('HEADER.TARGETS')}</h1>
    <p>{i18n('TARGETS.OFFER')}</p>
    <Default/>
    <RabbitDefault/>
    <RabbitNasta/>
    <Rabbit98816/>
    <Rabbit98811/>
    <Battue/>
    <Midi/>
    <Mini/>
  </>
)

interface AppHeaderMenuItemsList {
  name: string;
  link: string;
  disabled?: boolean;
}

export const appHeaderMenuItemsList: AppHeaderMenuItemsList[] = [
  { name: 'HEADER.ABOUT', link: '/' },
  { name: 'HEADER.TARGETS', link: '/targets' },
  { name: 'HEADER.PARTNERS', link: '/partners' },
  // { name: 'HEADER.NEWS', link: '/news', disabled: true }
]

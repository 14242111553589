import { Icon } from 'leaflet'
import React, { FC } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const locations: Array<{ name: string, location: [number, number] }> = require('./locations.json')
const bounds = locations.map(({ location }) => location)

export const Shipments: FC = () => {
  /*
  Ирландия, Англия, Бельгия, Польша, Сербия, Кипр, Греция, Литва, Латвия, Эстония, Финляндия, Швеция, Украина, Грузия, Азербайджан

СНГ:
Беларусь - Брест, Минск;
Казахстан – Нурсултан, Алматы, Шымкент
Россия – Москва, Питер, Краснодар, Ставрополь, Чечня, Саратов, Пенза, Владимир, Вологда, Н.Новгород, Киров, Челябинск, Курган, Екатеринбург, Краснодар, Сургут, Хабаровск, Владивосток, Сахалин, Петропавловск-Камчатский
   */

  return (
    <Map
      style={{ height: '300px' }}
      bounds={bounds}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" // https://operations.osmfoundation.org/policies/tiles/
      />
      {locations.map(({ name, location }, id) => {
        const icon = new Icon({
          iconSize: [24, 24],
          iconUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABJElEQVRIieXUPUoDQRjG8R8qaKcgBsHKGPAAFoK2HkE9Qu5grXcQWysjaBsrrVIavYFFWkGNFpoiWuwGlt3ZuJtNIz7wws687/yf+dgZ/oNqOMEDPuLo4jjOVdIB+vjOiT72q8CHY+CjGE5iUvtl5ul4w0oINJtjcIS9VN8rznGPBhYSuXl84q7oCh5TM3zBeiJfjw2TNd2icHhPDT4N1JzJHnhGMzkGXwXq0n2DHFZQHdktqifyG7Jb1AmB5nIMbrGTaC+J9vgybh9iMTCmsBqK3YFkbJYxgHYJeLssHLZKrGJ7EgNoFYC3JoXDqugPGvdErFUxgOYYg2ZV+EgXAfjVtOBE9+ApAe9heZoGsCt6DgbxdyHlPdch9fCMG1yXmtqf1g/2CJPvQAzABQAAAABJRU5ErkJggg=="
        })
        return (
          <Marker
            icon={icon}
            key={`marker-${id}`}
            position={location}
          >
            <Popup>
              <span>{name}</span>
            </Popup>
          </Marker>
        )
      })}
    </Map>
  )
}

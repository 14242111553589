import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { useAppStyles } from '../../../App/AppStyles'

const useClaysTypeStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column wrap'
    },
    '& > section': {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}))

export const TargetType: FC<{ header: string }> = ({ header, children }) => {
  const { paper } = useAppStyles()
  const { root } = useClaysTypeStyles()

  return (
    <Paper elevation={3} className={[paper, root].join(' ')}>
      <h3>{header}</h3>
      <section>
        {children}
      </section>
    </Paper>
  )
}

import React, { FC } from 'react'
import rabbitNinety1 from '../../../assets/target/rabbit-988-16-1.jpg'
import rabbitNinety2 from '../../../assets/target/rabbit-988-16-2.jpg'
import rabbitNinety3 from '../../../assets/target/rabbit-988-16-3.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Rabbit98811: FC = () => (
  <TargetType header={i18n('TARGET.RABBIT.988-16')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="98,8"
        thickness="16"
        weight="115"
        countPerPack="152"
        size="46 Х 23 Х 25"
        weightBrutto="18,0"
        packs="50"
        total="7 600"
        totalWeight="900"
      />
    </TargetInfoContainer>

    <TargetImages images={[rabbitNinety1, rabbitNinety2, rabbitNinety3]}/>
  </TargetType>
)

import React, { FC } from 'react'
import { Paper } from '@material-ui/core'
import { useAppbarStyles } from '../AppHeader/AppHeaderStyles'
import { i18n } from '../../i18n/i18n'
import { useAppFooterStyles } from './AppFooterStyles'

export const AppFooter: FC = () => {
  const { container, heading, info } = useAppFooterStyles()
  const { toolbar } = useAppbarStyles()

  return (
    <Paper elevation={0} className={container}>
      <div className={toolbar}>
        <h3 className={heading}>{i18n('FOOTER.CONTACTS.LABEL')}</h3>
        <div className={info}>
          <span>
            {i18n('FOOTER.CONTACTS.MAIL')}
            &nbsp;
            <a href="mailto:y208902@gmail.com">y208902@gmail.com</a>
          </span>
          <span>
            {i18n('FOOTER.CONTACTS.PHONE')} <a href="tel:+79201710562">+7 9201710562</a> (WhatsApp)
            <br/>
            {i18n('FOOTER.CONTACTS.PHONE')} <a href="tel:+37129208902">+371 29208902</a> (WhatsApp)
          </span>
          <a href="http://www.google.com">{i18n('FOOTER.CONTACTS.PAGE')}</a>
          {i18n('FOOTER.CONTACTS.ADDRESS')}
        </div>
      </div>
    </Paper>
  )
}

import React, { FC, useCallback } from 'react'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { currentLang } from '../../i18n/i18n'
import { useLanguageSwitchStyles } from './LanguageSwitchStyles'

export const LanguageSwitch: FC = () => {
  const { root, select, selectMenu } = useLanguageSwitchStyles()

  const onChange = useCallback(({ target: { value } }) => {
    localStorage.setItem('lang', value)
    window.location.reload()
  }, [])

  return (
    <FormControl className={root}>
      <Select
        autoWidth
        className={select}
        inputProps={{
          classes: {
            icon: selectMenu,
          },
        }}
        value={currentLang}
        onChange={onChange}
        displayEmpty
      >
        <MenuItem value='ru'>Русский</MenuItem>
        <MenuItem value='en'>English</MenuItem>
      </Select>
    </FormControl>
  )
}

import { makeStyles } from '@material-ui/core/styles'

export const useAppbarStyles = makeStyles({
  toolbar: {
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    padding: 0
  },
  appbar: {
    alignItems: 'start',
  },
  button: {
    color: '#fff !important' // find out why
  }
})

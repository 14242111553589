import React, { FC } from 'react'
import image from '../../../assets/target/standard-1.jpg'
import standard2 from '../../../assets/target/standard-2.jpg'
import { i18n } from '../../../i18n/i18n'
import { TargetImages } from './Common/TargetImages'
import { TargetInfo } from './Common/TargetInfo'
import { TargetInfoContainer } from './Common/TargetInfoContainer'
import { TargetType } from './Common/TargetType'

export const Default: FC = () => (
  <TargetType header={i18n('TARGET.DEFAULT')}>
    <TargetInfoContainer>
      <TargetInfo
        diameter="110"
        height="25"
        weight="105"
        countPerPack="200"
        size="46 Х 23 Х 30"
        weightBrutto="21,5"
        packs="40"
        total="8 000"
        totalWeight="880"
      />

      <strong>{i18n('TARGET.DEFAULT.INFO')}</strong>
    </TargetInfoContainer>

    <TargetImages images={[image, standard2]}/>
  </TargetType>
)
